import axios from "axios"
export const allKickfirst = () => {
    const region = "eu"
    const realm = "eredar"
    return axios.get('https://raider.io/api/v1/guilds/profile?region=eu&realm=eredar&name=It%20Is%20What%20It%20Is&fields=members').then((result) => {
        return {
            region: region,
            realm: realm,
            raiders: result
        }
    })
}