import { Table } from 'react-bootstrap'
import epgp from './epgp.json'
import React from 'react'
import {readSheet} from './sheet'
class EpgpTable extends React.Component {
    constructor(props){
        super(props)
        this.state={
            epgp: epgp
        }
    }
    filterTwinks(guildRoster){
        return guildRoster.filter((member)=>{
           return member.officerNote.includes(',')
        })
    }
    componentDidMount (){
        readSheet().then(res=>{
            this.setState({epgp:res})
        })
    }
    renderRow (member, index) {
        return <tr key={'row-'+index}><td>{member.name}</td><td>{member.ep}</td><td>{member.gp}</td><td>{member.pr}</td></tr>
    }
    sortRows (guildRoster){
        return guildRoster.sort((a,b) => b.pr - a.pr)
    }
    calculateValues (guildRoster) {
        let allGP = 0
        const guildRosterSorted= guildRoster.map((member)=>{
                const values = member.officerNote.split(',')
                let ep = Number(values[0])
                let gp = Number(values[1])
                gp += 500
                allGP+= gp
                let pr = 0
                if(ep>0){
                    pr = ep / gp
                }
                return { ...member, ep: ep, gp:gp, pr:pr.toFixed(3)}
            })
            console.log(allGP)
        return guildRosterSorted
    }
    render (){
        
        const rows = this.sortRows(this.calculateValues(this.filterTwinks(this.state.epgp)))
        return(
            <div>
                <Table variant='dark'>
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                EP
                            </th>
                            <th>
                                GP
                            </th>
                            <th>
                                PR
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index)=>this.renderRow(row, index))}
                    </tbody>
                </Table>
            </div>
        )
    }
}
export default EpgpTable