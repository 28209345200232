import { Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import React from "react";
import { allKickfirst } from "../const/allKickfirst";
import MythicTable from "../components/mythicTable";
import dayjs from "dayjs";
import EpgpTable from '../components/epgpTable/epgpTable';
import Modal from 'react-bootstrap/Modal';

const noderiowrapper = require("noderiowrapper");
//Rang 1-4 bei raider io & 8
const LASTWEEEK = 'LAST-WEEK'
const THISWEEEK = 'THIS-WEEK'
class RaiderContainer extends React.Component {
    nodeRIO = new noderiowrapper();
    constructor(props) {
        super(props)
        this.state = { raiderState: [], rowData: [], cutOff: 18, target: 4, selected: THISWEEEK, show: false }
    }
    updateCutOff = (event) => {
        this.setState({
            ...this.state, cutOff: event.target.value
        })
    }
    updateTarget = (event) => {
        this.setState({
            ...this.state, target: event.target.value
        })
    }
    getThisWeek = async () => {
        this.setState({ ...this.state, raiderState: [], selected: THISWEEEK })
        const allKickfirstInstance = await allKickfirst()
        const raidRoster = this.filterForRaider(allKickfirstInstance)
        console.log('raidroster:', raidRoster)
        raidRoster.push({
            name: 'Yasin',
            realm: 'blackmoore',
            region: 'eu'
        })
        this.nodeRIO.Character.guild = true;
        const results = await raidRoster.map(async (raider) => {
            this.nodeRIO.Character.getGear(raider.region, raider.realm, raider.name)
            return this.nodeRIO.Character.getMythicPlusWeeklyHighestRuns(raider.region, raider.realm, raider.name)
        })
        await Promise.all(results).then((values) => {
            this.setState({
                ...this.state,
                raiderState: values,
                rowData: this.createRowData(values, "mythic_plus_weekly_highest_level_runs")
            })
        })
    }
    getLastWeek = async () => {
        this.setState({ ...this.state, raiderState: [], selected: LASTWEEEK })
        const allKickfirstInstance = await allKickfirst()
        const raidRoster = this.filterForRaider(allKickfirstInstance)
        console.log(raidRoster)
        this.nodeRIO.Character.guild = true;
        const results = await raidRoster.map(async (raider) => {
            this.nodeRIO.Character.getGear(raider.region, raider.realm, raider.name)
            return this.nodeRIO.Character.getMythicPlusPreviousWeekHighestRuns(raider.region, raider.realm, raider.name)
        })
        await Promise.all(results).then((values) => {
            this.setState({
                ...this.state,
                raiderState: values,
                rowData: this.createRowData(values, "mythic_plus_previous_weekly_highest_level_runs")
            })
        })
    }
    componentDidMount() {
        this.getThisWeek()
    }

    handleClose = () => this.setState({ ...this.state, show: false })
    handleShow = () => this.setState({ ...this.state, show: true })

    filterForRaider(guild) {
        const raidRoster = []
        guild.raiders.data.members.forEach(raider => {
            if (
                (raider.rank === 0) ||
                (raider.rank === 1 && (
                    raider.character.name === "Getgregged" ||
                    raider.character.name === "Koyaanis" ||
                    raider.character.name === "Mollpriest" ||
                    raider.character.name === "Simbop" ||
                    raider.character.name === "Zakami")
                ) || (raider.rank === 2 && (
                    raider.character.name === "Grringer"
                )) || raider.rank === 3 || raider.rank === 6
            ) {
                raidRoster.push(raider.character)
            }
        })
        return raidRoster
    }

    createRowData(data, key = 'mythic_plus_weekly_highest_level_runs') {
        data = data.map((character) => {
            const rowCount = 8
            const rowData = []
            character.last_crawled_at = dayjs(character.last_crawled_at).format('HH:MM DD.MM.')
            let counter = 0
            let keySum = 0
            while (rowData.length < rowCount) {
                if (character[key].length > counter) {
                    rowData[counter] = character[key][counter].mythic_level
                    if (counter < 4) {
                        keySum += Number(character[key][counter].mythic_level)
                    }
                }
                else {
                    rowData[counter] = "--"
                }
                counter++;
            }
            character.rowData = rowData
            character.keySum = keySum
            return character
        })
        return this.sortRowData(data)
    }
    sortRowData(data) {
        return data.sort((a, b) => b.keySum - a.keySum)
    }

    render() {
        let titleText = ''
        if (this.state.selected === THISWEEEK) {
            titleText = 'Diese ID'
        } else if (this.state.selected === LASTWEEEK) {
            titleText = "Letze ID"
        }
        console.log(this.state)
        return (
            <div>
                <Modal variant="dark" className='epgp-modal' show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header variant="dark" closeButton>
                        <Modal.Title variant="dark">EPGP Ranking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body variant="dark">
                        <EpgpTable>
                        </EpgpTable>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="dark" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <h3 className="title tablebar">Raider Komponente</h3>
                <Row >
                    <Col className="input-col">
                        <FloatingLabel
                            controlId="cutOff"
                            label="Cut Off"
                            className="mb-3 input-label">
                            <Form.Control placeholder='' value={this.state.cutOff} onChange={this.updateCutOff} />
                        </FloatingLabel>
                    </Col>
                    <Col className="input-col">
                        <FloatingLabel
                            controlId="cutOff"
                            label="Anzahl Keys"
                            className="mb-3 input-label">
                            <Form.Control placeholder='' value={this.state.target} onChange={this.updateTarget} />
                        </FloatingLabel>
                    </Col>
                    <Col className="input-col">
                        <Button className="interaction-button" variant="dark" onClick={this.getLastWeek} disabled={this.state.selected === LASTWEEEK} size="lg">Letzte ID</Button>
                    </Col>
                    <Col className="input-col">
                        <Button className="interaction-button" variant="dark" onClick={this.getThisWeek} disabled={this.state.selected === THISWEEEK} size="lg">Diese ID</Button>
                    </Col>
                    <Col className="input-col">
                        <Button className="interaction-button" variant="dark" onClick={this.handleShow} size="lg" >Show EPGP</Button>
                    </Col>
                </Row>


                <Row className="tablebar" >
                    <Col>
                        <h3 className='row-title'>{titleText}</h3>
                    </Col>
                </Row>
                <MythicTable data={this.state.rowData} cutOff={this.state.cutOff} target={this.state.target}></MythicTable>
            </div>
        )
    }
}

export default RaiderContainer;