import './App.scss';
import RaiderContainer from './containers/raiderContainer';
function App() {
  return (
    <div className="App App-header">
        <RaiderContainer></RaiderContainer>
    </div>
  );
}

export default App;
